import React from 'react'
import Layout from '../../components/Default/Layout'
import PageHeader from '../../components/PageHeader/PageHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../components/Accordion/Accordion'
import AccordionTemplate from '../../components/Services/AccordionTemplate'
import Content from '../../../content/accordion/kariera'
import { graphql, useStaticQuery } from 'gatsby'
import { H3Blue, UnderlineYellow } from '../../components/Typography'

const Kariera = ({ data: { page } }) => {
  const { frontmatter } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />

      <div className="Paragraph--Wrapper container">
        <p>{frontmatter.description}</p>
        <p>
          Do týmu přivítáme nové kolegy a kolegyně. Čeká Vás příjemné a
          přátelské pracovní prostředí, flexibilní pracovní doba, možnost
          profesního růstu a stabilní i férové finanční ohodnocení. Podpoříme
          Vás také v dalším vzdělávání, ať už se jedná o profesní zkoušky, účast
          na konferencích nebo mentoring od zkušených kolegů.
        </p>
        <H3Blue>Praha</H3Blue>
        <UnderlineYellow />
        <Accordion
          items={[
            {
              title: Content.asistentDanovehoPoradce.title,
              description: (
                <AccordionTemplate {...Content.asistentDanovehoPoradce} />
              )
            },
            {
              title: Content.danovyManazer.title,
              description: <AccordionTemplate {...Content.danovyManazer} />
            }
          ]}
        />
        <br />
        <H3Blue>Jihlava</H3Blue>
        <UnderlineYellow />
        <Accordion
          items={[
            {
              title: Content.financniUcetni.title,
              description: <AccordionTemplate {...Content.financniUcetni} />
            },
            {
              title: Content.konzultantJihlava.title,
              description: <AccordionTemplate {...Content.konzultantJihlava} />
            }
          ]}
        />
      </div>
    </Layout>
  )
}

const KarieraData = ({ location }) => {
  const data = useStaticQuery(graphql`
    query Kariera {
      page: markdownRemark(frontmatter: { slug: { eq: "kariera" } }) {
        ...Meta
        frontmatter {
          title
          description
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return <Kariera data={data} />
}

export default KarieraData
