const topicSlug = {
  danovyPoradce: 'danovy-poradce/',
  danovyManazer: 'danovy-manazer/',
  asistentDanovehoPoradce: 'danovy-konzultant-praha/',
  konzultantJihlava: 'danovy-konzultant-jihlava/',
  asistentPravni: 'asistent-pro-pravni-problematiku/',
  asistent: 'asistent/',
  hlavniUcetni: 'hlavni-ucetni/',
  mzdovaUcetni: 'mzdova-ucetni/',
  financniUcetni: 'financni-mzdova-ucetni/',
  ucetni: 'samostatna-ucetni/',
  asistentVedeni: 'asistent-vedeni/',
  asistentSekret: 'asistent-sekretariatu/',
  asistentMarketing: 'asistent-marketing/',
  datovyAnalytikJunior: 'datovy-analytik-junior/',
  datovyKonzultantStaz: 'danovy-konzultant-staz/'
}

export const Content = {
  danovyPoradce: {
    title: 'Daňový/á poradce/poradkyně',
    slug: topicSlug.danovyPoradce,
    description:
      'V týmu přivítáme kolegu s praxí v oboru daní a účetnictví a ochotou se učit. Vaší prací bude zejména poradenství v oblasti DPPO, DPH, případně jiné specializaci. Spolupracovat budete se zkušenými kolegy.'
  },
  danovyManazer: {
    title: 'Daňový manažer / Tax manager',
    slug: topicSlug.danovyManazer,
    description:
      'V týmu přivítáme kolegu s praxí v oboru daní a účetnictví a manažerskými schopnostmi. Vaší prací bude zejména poradenství v oblasti DPPO, DPH, případně jiné specializaci.'
  },
  asistentPravni: {
    title: 'Daňový/á konzultant/ka pro právní problematiku – junior/senior',
    slug: topicSlug.asistentPravni,
    description:
      'Hledáme kolegyně a kolegy, kteří mají stejnou vizi a chtějí spolupracovat s kolegy s rozsáhlými znalostmi v oboru.'
  },
  asistentDanovehoPoradce: {
    title: 'Daňový/á konzultant/ka – junior',
    slug: topicSlug.asistentDanovehoPoradce,
    description: `Hledáme kolegyně a kolegy, kteří mají stejnou vizi a chtějí se s podporou kolegů s rozsáhlými znalostmi v budoucnu stát daňovým poradcem, nebo profesionálem v účetnictví.`
  },
  konzultantJihlava: {
    title: 'Daňový/á konzultant/ka - senior',
    slug: topicSlug.konzultantJihlava,
    description: `Hledáme kolegyně a kolegy, kteří mají stejnou vizi a chtějí se s podporou kolegů s rozsáhlými znalostmi v budoucnu stát daňovým poradcem, nebo profesionálem v účetnictví.`
  },
  asistent: {
    title: 'Asistent/ka daňového a účetního oddělení',
    slug: topicSlug.asistent,
    description:
      'Získejte možnost nahlédnout hlouběji do oboru a učit se od profesionálních daňových poradců. Vaší prací budou zejména rešerše, zpracování daňových přiznání a vedení účetnictví.'
  },
  hlavniUcetni: {
    title: 'Hlavní účetní',
    slug: topicSlug.hlavniUcetni,
    description: `Máte zkušenosti s vedením finančního a mzdového účetnictví? Pojďte se dále rozvíjet do přátelského týmu v centru Jihlavy. Nabízíme různorodou práci, zkušené kolegy a možnost se profesně posouvat.`
  },
  mzdovaUcetni: {
    title: 'Mzdová, mzdový účetní',
    slug: topicSlug.mzdovaUcetni,
    description: `Máte zkušenosti s vedením mzdového účetnictví? Pojďte se dále rozvíjet do přátelského týmu v centru Jihlavy. Nabízíme různorodou práci, zkušené kolegy a možnost se profesně posouvat.`
  },
  financniUcetni: {
    title: 'Mzdová, mzdový / finanční účetní',
    slug: topicSlug.financniUcetni,
    description: `Máte zkušenosti s vedením finančního účetnictví? Pojďte se dále rozvíjet do přátelského týmu v centru Jihlavy. Nabízíme různorodou práci, zkušené kolegy a možnost se profesně posouvat.`
  },
  ucetni: {
    title: 'Samostatná, samostatný účetní',
    slug: topicSlug.ucetni,
    description: `Máte zkušenosti s vedením finančního účetnictví? Pojďte se dále rozvíjet do přátelského týmu v centru Jihlavy. Nabízíme různorodou práci, zkušené kolegy a možnost se profesně posouvat.`
  },
  asistentVedeni: {
    title: 'Asistent/ka vedení',
    slug: topicSlug.asistentVedeni,
    description: `Hledáme pozitivního týmového hráče pro pro asistenci vedení kanceláře daňových a účetních poradců na adrese Senovážné náměstí. Nabízíme různorodou práci, zkušené kolegy a možnost se profesně posouvat.`
  },
  asistentSekret: {
    title: 'Asistent/ka sekretariátu',
    slug: topicSlug.asistentSekret,
    description: `Hledáme pozitivního týmového hráče pro sekretariát kanceláře daňových a účetních poradců na adrese Senovážné náměstí. Nabízíme různorodou práci, zkušené kolegy a možnost se profesně posouvat.`
  },
  asistentMarketing: {
    title: 'Asistent/ka marketingu',
    slug: topicSlug.asistentMarketing,
    description: `Hledáme pozitivního týmového hráče pro oddělení marketingu daňových a účetních poradců na adrese Senovážné náměstí. Nabízíme různorodou práci, zkušené kolegy a možnost se profesně posouvat.`
  },
  datovyAnalytikJunior: {
    title: 'Datový/á analytik/analytička - junior',
    slug: topicSlug.datovyAnalytikJunior,
    description: `Hledáme pozitivního týmového hráče pro nové oddělení datové analýzy na adrese Senovážné náměstí. Nabízíme různorodou práci, zkušené kolegy a možnost se profesně posouvat.`
  },
  datovyKonzultantStaz: {
    title: 'Placená stáž Daňový/á konzultant/ka - junior',
    slug: topicSlug.datovyKonzultantStaz,
    description: `Hledáme kolegyně a kolegy, kteří mají stejnou vizi a chtějí se s podporou kolegů s rozsáhlými znalostmi v budoucnu stát daňovým poradcem, nebo profesionálem v účetnictví.`
  }
}
export default Content
